<template>
    <div class="pagination-container mt15">
        <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :small="small"
            :disabled="disabled"
            :background="props.background"
            layout="->,total,prev, pager, next,sizes,jumper"
            :total="props.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>

    </div>
  </template>
  

  <script lang="ts" setup>
import { ref,defineProps,defineEmits } from 'vue'

const props = defineProps({
    currentPage:Number,
    pageSize:Number,
    total:Number,
    background: Boolean
})
const currentPage = ref(props.currentPage || 1)
const pageSize = ref(props.pageSize || 10)
const small = ref(false)
// const background = ref(false)
const disabled = ref(false)

const emit = defineEmits(['update:currentPage', 'update:pageSize','pagination'])

const handleSizeChange = (val: number) => {
  console.log(`${val} items per page`)
  emit('update:pageSize',val)
  emit('pagination', { perPage: val })
}
const handleCurrentChange = (val: number) => {
  console.log(`current page: ${val}`)
  emit('update:currentPage',val)
  emit('pagination', { page: val })
}
</script>

<style scoped>
.demo-pagination-block + .demo-pagination-block {
  margin-top: 10px;
}
.demo-pagination-block .demonstration {
  margin-bottom: 16px;
}
</style>