import request from '@/utils/request'

export const addBpmTemplate = (params?: any): Promise<any> => {
    return request.post('/web/bpm', params)
}

export const editBpmTemplate = (bpmTemplateId: number, params?: any): Promise<any> => {
    return request.put(`/web/bpm/${bpmTemplateId}`, params)
}

export const listBpmTemplate = (callMethod: string, query?: any): Promise<any> => {
    const params = {callMethod, ...query}
    return request.get('/web/bpm', {params})
}

export const getBpmTemplate = (bpmTemplateId: number): Promise<any> => {
    return request.get(`/web/bpm/${bpmTemplateId}`)
}
