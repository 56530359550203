import {createStore} from 'vuex'
import user from './modules/user'
import enums from "./modules/enums";
import log from "./modules/log";

export default createStore({
    modules: {
        user,
        enums,
        log,
    }
})
