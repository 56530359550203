export default function dispatchEventStroage() {
  const signSetItem = localStorage.setItem;

  localStorage.setItem = function (key: string, val: string): void {
    const setEvent: any = new Event('setItemEvent');
    setEvent.key = key;
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    signSetItem.apply(this, [key, val]); // 使用 rest 参数传递参数
  };
}
