import request from '@/utils/request'

/**         方法管理          */
// 方法列表
export const getMethods = (callMethod = 'manage', data?: any): Promise<any> => {
    const params = {callMethod, ...data}
    return request.get('/web/methods', {params})
}

// 创建方法
export const addMethod = (params: any): Promise<any> => {
    return request.post('/web/methods', params)
}

// 修改方法
export const editMethod = (params?: any): Promise<any> => {
    return request.put(`/web/methods/${params?.id}`, params)
}

// 删除方法
export const deleteMethod = (id: number): Promise<any> => {
    return request.delete(`/web/methods/${id}`)
}


/**         配件（配方）管理          */

//配方列表
export const getFormulas = (callMethod = 'manage', data?: any): Promise<any> => {
    const params = {callMethod, ...data}
    return request.get('/web/formulas', {params})
}
// 创建配方
export const addFormulas = (params: any): Promise<any> => {
    return request.post('/web/formulas', {callMethod: 'manage', ...params})
}
// 修改配方
export const editFormulas = (params?: any): Promise<any> => {
    return request.put(`/web/formulas/${params?.id}`, {callMethod: 'manage', ...params})
}

// 删除配方
export const deleteFormulas = (id: number): Promise<any> => {
    return request.delete(`/web/formulas/${id}`)
}

//配方详情
export const formulaInfo = (id: number): Promise<any> => {
    return request.get(`/web/formulas/${id}`)
}

/**         工序管理          */

// 工序列表
export const getProcedures = (callMethod = 'manage', data?: any): Promise<any> => {
    const params = {callMethod, ...data}
    return request.get('/web/procedures', {params})
}
// 创建工序
export const addProcedures = (params: any): Promise<any> => {
    return request.post('/web/procedures', {callMethod: 'manage', ...params})
}
// 修改工序
export const editProcedures = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/procedures/${id}`, {callMethod: 'manage', ...params})
}

// 删除工序
export const deleteProcedures = (id: number): Promise<any> => {
    return request.delete(`/web/procedures/${id}`)
}

//工序详情
export const proceduresInfo = (id: number): Promise<any> => {
    return request.get(`/web/procedures/${id}`)
}

/**         工艺管理          */
export const getCrafts = (params: any): Promise<any> => {
    return request.get('/web/crafts', {params})
}

export const getCraftDetail = (id: number): Promise<any> => {
    return request.get(`/web/crafts/${id}`)
}

export const addCraft = (params: any): Promise<any> => {
    return request.post('/web/crafts', params)
}

export const editCraft = (id: number, params: any): Promise<any> => {
    return request.put(`/web/crafts/${id}`, params)
}

export const trashCraft = (id: number): Promise<any> => {
    return request.delete(`/web/crafts/${id}`)
}

export const copyCraft = (id: number): Promise<any> => {
    return request.post(`/web/crafts/${id}`)
}

/**   工单管理     */
export const getOrders = (params: any): Promise<any> => {
    return request.get('/web/work-orders', {params})
}