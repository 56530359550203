import request from '@/utils/request'

// 员工列表
export const getStaffList = (params: any): Promise<any> => {
    return request.get('/web/staff', {params})
}
// 添加员工
export const addStaff = (params: any): Promise<any> => {
    return request.post('/web/staff',params)
}
// 员工详情
export const staffInfo = (id: number): Promise<any> => {
    return request.get(`/web/staff/${id}`)
}
// 修改员工信息
export const editStaffInfo = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/staff/${id}`,params)
}
// 获取部门树
export const getOrgTree = (params?: any): Promise<any> => {
    return request.get('/web/org', {params})
}
// 删除部门
export const deleteOrg = (params: any): Promise<any> => {
    return request.delete(`/web/org/${params}`)
}
// 修改部门信息
export const editOrg = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/org/${id}`,params)
}
// 添加子部门
export const addOrg = (params: any): Promise<any> => {
    return request.post('/web/org/',params)
}
// 岗位列表
export const getPositionList = (params: any): Promise<any> => {
    return request.get('/web/work-positions', {params})
}
// 添加岗位
export const addPosition = (params: any): Promise<any> => {
    return request.post('/web/work-positions',params)
}
// 员工详情
export const positionInfo = (id: number): Promise<any> => {
    return request.get(`/web/staff/${id}`)
}
// 修改岗位信息
export const editPositionInfo = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/work-positions/${id}`,params)
}
// 岗位下员工信息
export const positionStaff = (id:number,params:any)=>{
    return request.get(`/web/work-positions/${id}/staff`,{params})
}

