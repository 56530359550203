import request from '@/utils/request'



// 客户列表
export const getCustoms = (params?: any): Promise<any> => {
    return request.get('/web/companies', { params })
}

// 创建客户
export const addCustom = (params: any): Promise<any> => {
    return request.post('/web/companies',params)
}

// 修改客户信息
export const editCustom = (params?: any): Promise<any> => {
    return request.put(`/web/companies/${params?.id}`,params)
}

// 删除客户
export const deleteCustom = (id: number): Promise<any> => {
    return request.delete(`/web/companies/${id}`)
}

