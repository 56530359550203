import request from '@/utils/request'

export const getCommentList = (params?: any): Promise<any> => {
    return request.get('/web/comments', {params})
}

export const getComment = (id: number): Promise<any> => {
    return request.get(`/web/comments/${id}`)
}

export const addComment = (params: any): Promise<any> => {
    return request.post('/web/comments', params)
}

export const editComment = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/comments/${id}`, params)
}

export const deleteComment = (id: number): Promise<any> => {
    return request.delete(`/web/comments/${id}`)
}
