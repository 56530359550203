import request from '@/utils/request'


// 任务列表
export const getTasks = (params?: any): Promise<any> => {
    return request.get('/web/tasks', {params})
}

// 创建任务
export const addTask = (params: any): Promise<any> => {
    return request.post('/web/tasks', params)
}

// 修改任务
export const editTask = (params?: any): Promise<any> => {
    return request.put(`/web/tasks/${params?.id}`, params)
}

export const getForm = (id: number): Promise<any> => {
    return request.get(`/web/forms/${id}`)
}

export const addForm = (params?: any): Promise<any> => {
    return request.post('/web/forms', params)
}

export const editForm = (params?: any): Promise<any> => {
    return request.put(`/web/forms/${params?.id}`, params)
}

export const listForms = (callMethod: string, query: any): Promise<any> => {
    const params = {callMethod, ...query}
    return request.get('/web/forms', {params})
}
