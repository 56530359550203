import request from '@/utils/request'

/**      设备库管理       */
// 设备库列表
export const getEquipments = (params?: any): Promise<any> => {
    return request.get('/web/equipments', {params})
}

// 创建设备类
export const addEquipment = (params: any): Promise<any> => {
    return request.post('/web/equipments', params)
}

// 修改设备类
export const editEquipment = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/equipments/${id}`, params)
}

// 删除设备类
export const deleteEquipment = (id: number): Promise<any> => {
    return request.delete(`/web/equipments/${id}`)
}

/**       设备信息管理     */

// 设备信息列表
export const getDeviceList = (params?: any): Promise<any> => {
    return request.get('/web/equipment-instances', {params})
}

// 创建设备
export const addDevice = (params: any): Promise<any> => {
    return request.post('/web/equipment-instances', params)
}

// 修改设备
export const editDeviceInfo = (id: number, params?: any): Promise<any> => {
    return request.post(`/web/equipment-instances/${id}`, params)
}

// 删除设备
export const deleteDevice = (id: number): Promise<any> => {
    return request.delete(`/web/equipment-instances/${id}`)
}

export const deviceDetail = (id: number): Promise<any> => {
    return request.get(`/web/equipment-instances/${id}`)
}

/**           车间管理       */
// 创建/编辑车间
export const editWorkshops = (params: any): Promise<any> => {
    return request.post('/web/workshops', params)
}
// 车间列表
export const workshops = (): Promise<any> => {
    return request.get('/web/workshops')
}
// 删除车间
export const deleteWorkshop = (id: number): Promise<any> => {
    return request.delete(`/web/workshops/${id}`)
}
