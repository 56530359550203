import apis from '@/apis'
import type { ActionContext } from "vuex";

interface ILogModelState {
  visible:boolean,
  logs:{
    list:ILogItem[],
    total:number,
    perPage:number,
    page:number
  },
  type:{
    type:string,
    id:string
  }
}
interface ILogItem{
  title:string,
  content:string,
  staff:{
    avatar:string,
    name:string
  }
}
const log = {
  namespaced: true,
  state: {
    visible:false,
    logs:{
      list:[],
      total:0,
      perPage:5,
      page:1,
      
    },
    type:{
      type:'',
      id:null
    }
  },
  mutations: {
    triggle(state: ILogModelState,value:boolean) {
      state.visible = value
    },
    setLogs(state: ILogModelState, value: ILogItem[]){
      
      state.logs.list = value
      console.log('list:',state.logs.list)
    },
    setTotal(state: ILogModelState, value: number){
      state.logs.total = value
      console.log('total:',state.logs.total)
    },
    setType(state: ILogModelState, value: any){
      state.type = value
    },
    resetLogs(state: ILogModelState){
      state.logs={
        list:[],
        total:0,
        perPage:10,
        page:1,
        
      }
    },
  },
  actions: {
    async showLog(
      { commit,dispatch }: ActionContext<ILogModelState, any>,
      params:{type:string,id:number}
    ) {
      // 重置数据
      commit('resetLogs')
      // 设置当前日志分类
      if(params){
        commit('setType',params)
      }
      // 请求数据
      await dispatch('getLogs')
      commit('triggle',true)
      
    },
    async getLogs({commit,state}: ActionContext<ILogModelState, any>){
      const res = await apis.common.logs(
        state.type.type,
        state.type.id,{
          page:state.logs.page,
          perPage:state.logs.perPage
        }
      )
      commit('setLogs',res.data)
      commit('setTotal',res.meta.total)
    }
  }
 
};
export default log;
