import request from '@/utils/request'

export const getWorkList = (params: any): Promise<any> => { // 工单列表
  return request.get('/web/work-orders', {params})
}

export const getCraftNodes = (): Promise<any> => { // 可以创建的工单列表
  return request.get('/web/craft/nodes')
}

export const getCraftNodesDetails = (id: number): Promise<any> => { // 可以创建工单表单数据
  return request.get(`/web/craft/nodes/${id}`)
}

export const createWorkOrder = (data: any): Promise<any> => { // 创建工单
  return request.post('/web/work-orders', data)
}

export const getWorkOrders = (id: string): Promise<any> => { // 工单详情
  return request.get(`/web/work-orders/${id}`)
}

export const createWorkExecute = (workOrderId: number, data: any): Promise<any> => { // 作业人员填写工单
  return request.post(`/web/work-orders/${workOrderId}/execute`, data)
}

export const createWorkCheck = (workOrderId: number, data: any): Promise<any> => { // 质检人员填写工单
  return request.post(`/web/work-orders/${workOrderId}/check`, data)
}

export const createWorkConfirm = (workOrderId: number, data: any): Promise<any> => { // 确认人员填写工单
  return request.post(`/web/work-orders/${workOrderId}/confirm`, data)
}

export const revokeWork = (workOrderId: number): Promise<any> => { // 撤消工单
  return request.post(`/web/work-orders/${workOrderId}/revoke`)
}