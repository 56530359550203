import apis from '@/apis'
import {ActionContext} from 'vuex'
interface IEnumsState{
    enumsList:any[]
}
export default {
    namespaced: true,
    state: {
        enumsList: {}
    },
    mutations: {
        setEnums: (state: any, val: any) => {
            console.log(val)
            state.enumsList = {
                ...state.enumsList,
                ...val
            }
        }
    },
    actions: {
        setEnums: (state: any, val: any) => {
            state.commit('setEnums', val)
        },
        getEnum:async({ state,commit }: ActionContext<IEnumsState, any>, val: any) => {
            if(state.enumsList[val]){
                return state.enumsList[val]
            }else{
                // 接口获取
                const res = await apis.common.enums({enums: [val]})
                commit('setEnums', res)
                return res.MaterialType
            }
            
        },
    }
}
