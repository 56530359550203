import request from '@/utils/request'

/**      数据指标       */

// 数据指标库列表
export const getDataMetrics = (params?: any): Promise<any> => {
    return request.get('/web/data-metrics', { params })
}

// 创建
export const addDataMetrics = (params: any): Promise<any> => {
    return request.post('/web/data-metrics',params)
}

// 修改
export const editDataMetrics = (id:number,params?: any): Promise<any> => {
    return request.put(`/web/data-metrics/${id}`,params)
}

// 删除
export const deleteDataMetrics = (id: number): Promise<any> => {
    return request.delete(`/web/data-metrics/${id}`)
}

/**       设备信息管理     */

// 设备信息列表
export const getDeviceList = (params?: any): Promise<any> => {
    return request.get('/web/equipment-instances', { params })
}

// 创建设备
export const addDevice = (params: any): Promise<any> => {
    return request.post('/web/equipment-instances',params)
}

// 修改设备
export const editDeviceInfo = (id:number,params?: any): Promise<any> => {
    return request.post(`/web/equipment-instances/${id}`,params)
}

// 删除设备
export const deleteDevice = (id: number): Promise<any> => {
    return request.delete(`/web/equipment-instances/${id}`)
}

/**           车间管理       */
// 创建/编辑车间
export const editWorkshops = (params: any): Promise<any> => {
    return request.post('/web/workshops',params)
}
// 车间列表
export const workshops = (): Promise<any> => {
    return request.get('/web/workshops')
}
// 删除车间
export const deleteWorkshop = (id: number): Promise<any> => {
    return request.delete(`/web/workshops/${id}`)
}
