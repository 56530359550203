import request from '@/utils/request'


// 公告列表
export const getNotices = (params?: any): Promise<any> => {
    return request.get('/web/announcements', {params})
}

// 创建公告
export const addNotice = (params: any): Promise<any> => {
    return request.post('/web/announcements', params)
}

// 公告详情
export const noticeInfo = (id: number): Promise<any> => {
    return request.get(`/web/announcements/${id}`)
}

export const noticeReadInfo = (id: number): Promise<any> => {
  return request.get(`/web/announcements/${id}`)
}

// 修改公告
export const editNotice = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/announcements/${id}`, params)
}

// 删除公告
export const deleteNotice = (id: number): Promise<any> => {
    return request.delete(`/web/announcements/${id}`)
}


