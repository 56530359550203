<template>
  <el-watermark class="page-container" :content="[formatName(user.name), formatMobile(user.mobile)]"  :z-index="9999">
<!--  <el-watermark class="page-container" :content="['演示用户', '186****9083']" :z-index="9999">-->
    <router-view/>
    <LogDialog  />
  </el-watermark>
</template>

<script setup lang="ts" name="app">
import {useStore} from "vuex";
import {computed, onMounted} from 'vue'
import LogDialog from "@/components/LogDialog.vue";
import {useRoute} from "vue-router";


const store = useStore();
const user = computed(() => store.state.user.user)

const currentRoute = useRoute();
console.log(currentRoute.path)
console.log(JSON.stringify(store.state.user))

// 隐藏手机号中间四位
const formatMobile = (mobile: string) => {
  if (!mobile) {
    return '';
  }
  return mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

// 隐藏姓名部分字符，假设隐藏姓氏之后的字符
const formatName = (name: string) => {
  if (!name) {
    return '';
  }
  if (name.length <= 1) {
    return '*';
  } else if (name.length === 2) {
    return name[0] + '*';  // 姓名长度为2时，隐藏名字部分
  } else {
    return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1]; // 隐藏中间部分字符
  }
}

onMounted(async () => {

});
</script>
<style lang="less">
html, body, ul, li {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; // 10px
}

li {
  list-style: none;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  width: 100%;
  height: 100%;
}

.page-container {
  width: 100%;
  height: 100%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.a, a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.el-drawer__header {
  margin-bottom: 0 !important;

  .el-drawer__title {
    font-size: 1.7rem !important;
    font-weight: 500;
    color: #333333;
  }
}

.el-tag {
  --el-tag-border-color: transparent !important;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-top {
  display: flex;
  align-items: flex-start;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  margin-bottom: 2rem;

  .el-form--inline {
    height: 3.2rem;
  }
}

.table {
  border-radius: .5rem;
  box-shadow: 0 0 .1rem .1rem rgba(0, 0, 0, .05);

  &.normal {
    box-shadow: none;
  }

  th {
    background: #f0f0f0 !important;
  }
}

.category-component .el-tree .el-tree-node__content {
  margin: 0.5em auto;
}
</style>
