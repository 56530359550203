import request from '@/utils/request'

// 链接库列表
export const getSurvey = (params?: any): Promise<any> => {
    return request.get('/web/surveys', {params})
}

export const getSurveyDetail = (id: number): Promise<any> => {
    return request.get(`/web/surveys2/${id}`)
}

export const addSurvey = (params: any): Promise<any> => {
    return request.post('/web/surveys', params)
}

export const editSurvey = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/surveys/${id}`, params)
}

// 删除链接类
export const deleteSurvey = (id: number): Promise<any> => {
    return request.delete(`/web/surveys/${id}`)
}


export const addAnswer = (params: any): Promise<any> => {
    return request.post('/web/survey2/answers/save', params)
}

export const editAnswer = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/survey/answers/${id}`, params)
}

export const getAnswer = (id: number): Promise<any> => {
    return request.get(`/web/survey/answers/${id}`)
}
export const getAnswerList = (params?: any): Promise<any> => {
    return request.get('/web/survey/answers', {params})
}

export const exportAnswer = async (id: number): Promise<any> => {
    const params = {'survey_id': id}
    // return request.post('/web/survey/answers/export', params)
    return request.get('/web/survey/answers_export?survey_id=' + id, {
        responseType: 'blob',
        // headers:{},
        // withCredentials: true
    });
}