<template>
  <div class="system-menu-dialog-container">
    <el-dialog title="日志" width="840px" v-model="log.visible" destroy-on-close>
      <el-table :data="log.logs.list" border stripe row-key="path" :tooltip-options="{effect: 'light', placement: 'bottom'}"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <!-- <el-table-column prop="staff.name" label="序号"/> -->
        <el-table-column prop="created_at" label="时间" width="160"/>
        <el-table-column prop="staff.name" label="操作人" width="120"/>
        <el-table-column prop="action" label="操作类型" width="110"/>
        <el-table-column prop="content" label="操作内容" show-overflow-tooltip/>
      </el-table>

      <Pagination v-show="log.logs.total>0" :total="log.logs.total" v-model:current-page="log.logs.page"
                  v-model:page-size="log.logs.perPage" @pagination="getTableData"/>
      <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="closeDialog" size="default">关闭</el-button>
            </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup lang="ts" name="departmentDialog">
import {ref, reactive, defineExpose, computed, onMounted} from 'vue';
import {useStore} from 'vuex'
import {ElMessage} from 'element-plus';
import apis from '@/apis';
// 引入组件
import Pagination from './Pagination.vue'

const store = useStore()
console.log(store.state)

const commonLog = computed(() => {
  console.log('commonLog更新', store.state.commonLog)
  return store.state.commonLog
})
const log = computed(() => {
  console.log(store.state.log, '===')
  return store.state.log
})
// const visiable = computed(() => {
//     console.log(store.state.commonLog.visiable,'visiable更新===------')
//     return store.state.commonLog.visiable
// })
const user = computed(() => {
  console.log(store.state.user, 'user更新===------')
  return store.state.user
})

const state = reactive({
  listQuery: {
    page: 1,
    perPage: 10
  },
  visiable: false,
  tableData: []
});

const triggDialog = () => {
  state.visiable = !state.visiable
}
/**
 * 弹窗隐藏
 */
const closeDialog = () => {
  store.commit('log/triggle', false)
}

/**
 * 请求数据
 */
const getTableData = () => {
  store.dispatch('log/getLogs')
}
onMounted(async () => {
  console.log('logs mounted')

})


defineExpose({
  triggDialog
})


</script>
