import request from '@/utils/request'

// 链接库列表
export const getDatalink = (params?: any): Promise<any> => {
    return request.get('/web/datalink', {params})
}

export const getDatalinkOne = (id?: number | undefined): Promise<any> => { // 任务列表
    return request.get(`/web/datalink/${id}`)
}

// 创建链接类
export const addDatalink = (params: any): Promise<any> => {
    return request.post('/web/datalink', params)
}

// 修改链接类
export const editDatalink = (id: number, params?: any): Promise<any> => {
    return request.put(`/web/datalink/${id}`, params)
}

// 删除链接类
export const deleteDatalink = (id: number): Promise<any> => {
    return request.delete(`/web/datalink/${id}`)
}
