import request from '@/utils/request'



// 目标列表
export const getTargets = (params?: any): Promise<any> => {
    return request.get('/web/targets', { params })
}

// 创建目标
export const addTarget = (params: any): Promise<any> => {
    return request.post('/web/targets',params)
}

// 修改目标
export const editTarget = (params?: any): Promise<any> => {
    return request.put(`/web/targets/${params?.id}`,params)
}

// 删除目标
export const deleteTarget = (id: number): Promise<any> => {
    return request.delete(`/web/targets/${id}`)
}

