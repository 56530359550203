import apis from "@/apis/index";
import {local, LOCAL_STORAGE} from "@/utils/storage";
import cookie from "@/utils/cookie";
import type {Commit, ActionContext} from "vuex";

interface IInfoState {
    id: string;
    name: string;
}

interface ILoginModelState {
    user: IInfoState;
    company: IInfoState;
}

const user = {
    namespaced: true,
    state: () => ({
        user: {},
        company: {},
        visible: false
    }),
    getter: {},
    mutations: {
        setVisible(state: any) {
            state.visible = true
        },
        setUser(state: ILoginModelState, value: IInfoState) {
            console.log("user:", value);
            state.user = value
        },
        setCompany(state: ILoginModelState, value: IInfoState) {
            state.company = value;
        },
    },
    actions: {
        /**
         * 当前用户信息
         */
        async setUserInfo({commit}: ActionContext<ILoginModelState, any>) {
            const res = await apis.user.userInfo();
            // TODO
            commit("setUser", res);
            console.log("set user", res);
        },
        /**
         * 登出操作
         */
        async logout({commit}: ActionContext<ILoginModelState, any>) {
            // 删除缓存
            local.remove(LOCAL_STORAGE.token)
            local.remove(LOCAL_STORAGE.user)
            local.remove(LOCAL_STORAGE.company)
            // 删除状态值
            commit("setUser", {});
            commit("setCompany", {});
        },
        /**
         * 登录操作
         * @param loginResult 登录接口返回
         */
        async login(
            {commit}: ActionContext<ILoginModelState, any>,
            loginResult: any
        ) {
            const {token, user, company} = loginResult;
            // 设置缓存
            local.set(LOCAL_STORAGE.token, token);
            local.set(LOCAL_STORAGE.user, user);
            local.set(LOCAL_STORAGE.company, company);
            // 更新用户信息公司信息
            commit("setUser", user);
            commit("setCompany", company);
        },
        async setCompany({commit}: ActionContext<ILoginModelState, any>, company: IInfoState) {
            local.set(LOCAL_STORAGE.company, company);
            commit("setCompany", company);
        }
    },
};
export default user;
