import request from '@/utils/request'

// 权限点
export const getPermissions = (): Promise<any> => {
    return request.get('/common/company/permissions')
}

// 角色列表
export const getRoles = (params?: any): Promise<any> => {
    return request.get('/web/roles', { params })
}

// 角色员工列表
export const getRoleStaff = (id:number,params:any): Promise<any> => {
    return request.get(`/web/roles/${id}/staff`,{params})
}

// 创建角色
export const addRole = (params: any): Promise<any> => {
    return request.post('/web/roles',params)
}
// 该角色下所有权限
export const rolePermissions = (id: number): Promise<any> => {
    return request.get(`/web/roles/${id}/permissions`)
}
// 修改角色
export const editRoleInfo = (params?: any): Promise<any> => {
    return request.put(`/web/roles/${params?.id}`,params)
}
// 角色授权
export const authorizeRole = (id:number | null,params: any): Promise<any> => {
    return request.post(`/web/roles/${id}/authorize`,params)
}
// 用户授权
export const authorizeStaff = (id:number | null,params: any): Promise<any> => {
    return request.post(`/web/roles/${id}/bind-staff`,params)
}
// 删除角色
export const deleteRole = (id: number): Promise<any> => {
    return request.delete(`/web/roles/${id}`)
}
// 用户绑定
export const bindRoles = (id:number | null,params: any): Promise<any> => {
    return request.post(`/web/staff/${id}/bind-roles`,params)
}

