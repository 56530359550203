import request from '@/utils/request'

export const getTask = (params?: any): Promise<any> => { // 任务列表
  return request.get('/web/tasks', {params})
}

export const getTaskDetails = (taskId?: number | undefined): Promise<any> => { // 任务列表
  return request.get(`/web/tasks/${taskId}`)
}

export const createTask = (data: any): Promise<any> => { // 创建
  return request.post('/web/tasks', data)
}

export const editTask = (taskId: any, data: any): Promise<any> => { // 修改
  return request.put(`/web/tasks/${taskId}`, data)
}

export const deleteTask = (id: number): Promise<any> => { // 删除任务
  return request.delete(`/web/tasks/${id}`)
}

export const getTaskRecords = (taskId?: string, data?: any): Promise<any> => { // 创建任务进展
  return request.get(`/web/tasks/${taskId}/records`, {data})
}

export const createTaskRecords = (taskId?: string, data?: any): Promise<any> => { // 创建任务进展
  return request.post(`/web/tasks/${taskId}/records`, data)
}

export const createTaskCommon = (data?: any): Promise<any> => {
  return request.post('/web/task-comments', data)
}

export const deleteTaskCommon = (id: number): Promise<any> => { // 删除评论
  return request.delete(`/web/task-comments/${id}`)
}

export const deleteRecord = (taskId: number, recordId: number): Promise<any> => { // 删除进度
  return request.delete(`/web/tasks/${taskId}/records/${recordId}`)
}