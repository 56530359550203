import request from '@/utils/request'

export const listModels = (callMethod: string, query: any): Promise<any> => {
    const params = {callMethod, ...query}
    return request.get(`/web/models`, {params})
}

export const listModelFields = (modelId: number): Promise<any> => {
    return request.get(`/web/models/${modelId}`)
}

export const addModel = (params?: any): Promise<any> => {
    return request.post('/web/models', params)
}

export const editModel = (params?: any): Promise<any> => {
    return request.put(`/web/models/${params?.id}`, params)
}
