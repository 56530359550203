import request from '@/utils/request'

export const getWeatherWnl = (): Promise<any> => {
  return request.get('/web/workbench/weather-wnl')
}

export const getShortcuts = (): Promise<any> => {
  return request.get('/web/workbench/shortcuts')
}

export const getGroupList = (): Promise<any> => {
  return request.get('/web/workbench/group-list')
}

export const getAdPositions = (): Promise<any> => {
  return request.get('/web/workbench/ad-positions')
}

export const getMessages = (params: any): Promise<any> => {
  return request.get('/web/messages', {params})
}

export const readMessage = (data: any): Promise<any> => {
  return request.post('/web/messages/read', data)
}

export const delMessage = (data: any): Promise<any> => {
  return request.post('/web/messages/delete', data)
}