import request from '@/utils/request'

export const login = (params: any): Promise<any> => {
    return request.post('/user/web/login', params)
}

export const logout = (): Promise<any> => {
    return request.post('/user/web/logout')
}

export const companies = (): Promise<any> => {
    return request.get('/user/web/companies')
}

// 获取用户信息
export const userInfo = (): Promise<any> => {
    return request.get('/user/web/info')
}

// 发送验证码
export const sendCaptcha = (params: any): Promise<any> => {
    return request.post('/common/captcha/send', params)
}

// 验证码校验
export const checkCaptcha = (params: any): Promise<any> => {
    return request.post('/common/captcha/check', params)
}

// 找回密码
export const lostPassword = (params: any): Promise<any> => {
    return request.post('/user/web/lost-password', params)
}

// 修改密码
export const changePassword = (params: any): Promise<any> => {
    return request.post('/user/web/change-password', params)
}

export const changeCompany = (companyId: number): Promise<any> => {
    return request.post('/user/web/change-company', {companyId})
}

export const uploadAvatar = (formData: any) => {
    return request.post('/user/web/upload-avatar', formData, {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export const permissions = (type: string, client: string, code = null) => {
    return request.get('/user/web/permissions', {params: {type, client, code}})
}
